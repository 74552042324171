import { DocsUploadType } from '../enums';
import { ExternalAuthDetails } from '../types';

type BridgeError = {
  error_code: string;
  error_message: string;
  error_type: string;
};

type EventCommonPayload = {
  bridge_token: string;
  product_type?: string;
};

type EventWithEmployerPayload = EventCommonPayload & {
  provider_id?: string | null;
  employer?: {
    name: string;
  } | null;
};

export type TaskEventCommonPayload = EventWithEmployerPayload & {
  task_id?: string;
  public_token?: string;
};

type ScreenViewNames =
  | 'SELECT_EMPLOYER'
  | 'PROVIDER_LIST'
  | 'PROVIDER_LOGIN'
  | 'PROVIDER_ORG_ID'
  | 'MFA'
  | 'ERROR'
  | 'SUCCESS'
  | 'DOC_UPLOAD'
  | 'DOC_UPLOAD_TYPES'
  | 'SELF_EMPLOYED';

// Subtypes EVENT

type PublicMessageEventPayloadByType = {
  ERROR: TaskEventCommonPayload & {
    error: BridgeError;
  };
  SUCCESS: TaskEventCommonPayload & {
    provider_id?: string;
  };
  LINK_CREATED: TaskEventCommonPayload & {
    provider_id?: string;
  };
  LOGIN_COMPLETE: TaskEventCommonPayload & {
    provider_id?: string;
  };
  SCREEN_VIEW: EventWithEmployerPayload & {
    view_name: ScreenViewNames;
    doc_type?: DocsUploadType;
    error?: BridgeError;
  };
  OPEN: EventWithEmployerPayload;
  EMPLOYER_SELECTED: EventWithEmployerPayload;
  DOCUMENTS_UPLOADED: EventWithEmployerPayload & {
    doc_type: DocsUploadType;
  };
  DOC_UPLOAD_OPENED: EventWithEmployerPayload;
  OAUTH_OPENED: EventCommonPayload & {
    task_id?: string;
    provider_id?: string;
    url: string;
  };
  MANUAL_DDS: EventCommonPayload &
    (
      | {
          action: 'EXTERNAL_FLOW';
          depositType?: never;
        }
      | {
          action: 'DOWNLOAD' | 'EMAIL' | 'SHARE';
          depositType: string;
        }
    );
};

// It should be for internal use only for mobile sdk
type InternalMessageEventPayloadByType = {
  START_EXTERNAL_LOGIN: Partial<ExternalAuthDetails>;
  START_HEADERS_COLLECTION: Partial<ExternalAuthDetails>;
};

export type MessageEventPayloadByType = PublicMessageEventPayloadByType & InternalMessageEventPayloadByType;

type GetMessageEventData<T> = {
  [TType in keyof T]: {
    type: TType;
    payload: T[TType];
  };
}[keyof T];

export type MessageEventData = GetMessageEventData<MessageEventPayloadByType>;
export type PublicMessageEventData = GetMessageEventData<PublicMessageEventPayloadByType>;
