import { MessageEventData } from './message-event-data';

export enum FlowMessage {
  Available = 'flow/available', // Inlined in index.html
  TitleChange = 'flow/title-change',
  OpenExternalLink = 'flow/open-external-link',
  Onload = 'flow/onload',
  Event = 'flow/event',
  Close = 'flow/close',
  Success = 'flow/success',
}

// GENERAL

export type TruvBridgeMessageAvailable = {
  type: FlowMessage.Available;
};

export type TruvBridgeMessageEvent = {
  type: FlowMessage.Event;
  data: MessageEventData;
};

export type TruvBridgeClosePayload = {
  type: FlowMessage.Close;
  data?: {
    employer: {
      name: string;
    };
  };
};

type TruvBridgeSuccessChangedPayload = {
  type: FlowMessage.Success;
  data: {
    publicToken: string;
    taskId: string;
    employer?: {
      name: string;
    };
  };
};

type TruvBridgeOnLoadChangedPayload = {
  type: FlowMessage.Onload;
};

type TruvBridgeTitleChangePayload = {
  type: FlowMessage.TitleChange;
  data: {
    title: string;
  };
};

type TruvBridgeOpenExternalLinkPayload = {
  type: FlowMessage.OpenExternalLink;
  data: {
    url: string;
  };
};

export type TruvBridgeMessage =
  | TruvBridgeMessageEvent
  | TruvBridgeClosePayload
  | TruvBridgeSuccessChangedPayload
  | TruvBridgeOnLoadChangedPayload
  | TruvBridgeMessageAvailable
  | TruvBridgeTitleChangePayload
  | TruvBridgeOpenExternalLinkPayload;
