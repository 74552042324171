import { CategorizedCompaniesResponseRaw } from './employers';
import { ProductType } from './enums';
import { FramePosition } from './frame';
import { BridgeWidgetInitConfigMessageData, OnEventCallback } from './messaging';
import type { BridgeTokenResponse } from './types.zod';

export type BridgeInitConfig = {
  bridgeToken: string; // bridge_token
  isOrder?: boolean;
  autoOpen?: boolean;
  clientName?: string;
  product?: ProductType;
  trackingInfo: string;
  additionalInfo?: BridgeWidgetInitConfigMessageData['additionalInfo'];
  position?: FramePosition;
  params?: Record<string, string>; // Use this params instead of window.location.search
  internal?: {
    /**
     * Callback to process external links. If not provided, the default behavior is to open the link by browser.
     * @param url - URL to process
     */
    onExternalLinkProcessor?(url: string): void;
  };
  onClose?: () => void;
  onEvent?: OnEventCallback;
  onLoad?: () => void;
  onSuccess?: (publicToken?: string, metadata?: { task_id: string; employer?: { name: string } }) => void;
};

export type SingleBridgeGuardFunction = () => (() => void) | null;

export type BridgeApiPublicInterface = {
  open: (props?: { additionalInfo?: BridgeWidgetInitConfigMessageData['additionalInfo'] }) => void;
  close: () => void;
  setBridgeTokenResponse: (data: BridgeTokenResponse) => void;
  setCategorizedCompaniesResponse: (data: CategorizedCompaniesResponseRaw) => void;
};

export type BridgeGlobalObject = {
  init(config: BridgeInitConfig): BridgeApiPublicInterface;
};
