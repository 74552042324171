import { KeyEnv, ProductType } from './enums';
import type { LabelPerProduct, Provider } from './types.zod';

export type Jsonable = string | number | boolean | null | Jsonable[] | { [key: string]: Jsonable };

export type VerificationProductType = ProductType.EMPLOYMENT | ProductType.INCOME;

export type Link = {
  text: string;
  url: string;
};

export type Links = { [key: string]: Link };

export type ExternalAuthDetails = {
  url: string;
  provider_id: string;
  is_logged_in: unknown;
  external_login_type?: 'fallback' | 'always';
  external_login_type_mobile_app?: 'fallback' | 'always';
  script?: {
    url: string;
    background_url: string;
  };
};

export type Partner = {
  bridgeToken?: string | null;
  clientLogo?: string | null;
  clientLogoSquare?: string | null;
  primaryColor?: string | null;
  companyMappingId?: string | null;
  envType: KeyEnv | null;
  howItWorksType?: 'link' | 'full_screen' | null;
  consentScreenType?: 'link' | 'full_screen' | null;
  isPreselectedProvider?: boolean;
  isPrivacyPolicyHidden?: boolean;
  product: ProductType;
  allowedProducts: ProductType[];
  provider?: Provider | null;
  title?: string;
  trackingInfo: string;
  isBeforeYouGoScreenHidden?: boolean;
  isTruvLogoHidden?: boolean;
  isConfettiHidden?: boolean;
  isTaxProductEnabled?: boolean;
  showExitButtonTimeout?: number;
  productName: string;
  shortProductName: string;
};

export type PartnerDataForAnalytics = Partial<Pick<Partner, 'title' | 'product' | 'bridgeToken'>>;

export const dummyPartner = {};

export type TextLabels = {
  income_employer_search_screen_header?: string | null;
  employer_search_screen_header?: string | null | LabelPerProduct;
  employer_search_screen_subheader?: string | null | LabelPerProduct;
  client_bottom_logo?: {
    dark: string;
    light: string;
  };
  move_privacy_above_submit?: boolean;
};

export type CloseError = {
  error_type: string;
  error_code: string;
  error_message: string;
};

export type CreateCheckErrorType = {
  error_type: string;
  error_code: string;
  error_message: string;
};

export type Address = {
  zip: string;
  city: string;
  state: string;
  street: string;
  country: string;
};

export type GoToCommand = {
  type: ExitGotoNextScreen | ExitGoto;
};

export enum ExitGoto {
  ProviderHelp = 'ProviderHelp',
  FindPaystub = 'FindPaystub',
  FindW2 = 'FindW2',
  FindInsurancePolicy = 'FindInsurancePolicy',
}

export enum ExitGotoNextScreen {
  ManualDDS = 'ManualDDS',
  DataSourceDocsUpload = 'DataSourceDocsUpload',
  DataSourceFinancialAccounts = 'DataSourceFinancialAccounts',
  DataSourcePayroll = 'DataSourcePayroll',
  DataSourceTax = 'DataSourceTax',
}

export type ExternalFeature = 'external_app_login';
