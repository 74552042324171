import { MessageBridgeEvent } from './bridge-callbacks';
import { PublicMessageEventData } from './message-event-data';

export const TRUV_ORDER_MESSAGE_TYPE = 'truv/order';

export enum TruvOrderEventType {
  Order = 'order',
  Bridge = 'bridge',
}

type BridgeEvent = MessageBridgeEvent | PublicMessageEventData;

export type TruvOrderEvent =
  | ({
      source: TruvOrderEventType.Order;
    } & { type: 'CLOSE' | 'LOAD' | 'SUCCESS'; payload?: never })
  | ({
      source: TruvOrderEventType.Bridge;
      payload: { bridge_token: string }; // Ensure that we have the bridge_token in payload
    } & BridgeEvent);

export type TruvOrderEventMessage = {
  type: typeof TRUV_ORDER_MESSAGE_TYPE;
  message: TruvOrderEvent;
};
