import type { CompaniesCategoryRaw, MappingEmployerRaw } from './employers.zod';
import { MappingType } from './enums';
import type { LogoSlug } from './types.zod';
export type * from './employers.zod';

export type CompaniesCategory = Omit<CompaniesCategoryRaw, 'mappings'> & {
  mappings: MappingEmployer[];
};

export type Employer = MappingEmployer | FakeEmployer;

export type MappingEmployer = MappingEmployerRaw & {
  type: MappingType.Truv;
};

export type FakeEmployer = {
  type: MappingType.Manual;
  name: string;
  logo_url?: string;
  logo_slug?: LogoSlug;
};
