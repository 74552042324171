import { getMobileOperatingSystem } from '@citadel/tools/utils/getMobileOs';
import {
  SMALL_SCREEN_WIDTH_PX,
  getDesktopWidgetHeightByScreenSize,
  getDesktopWidgetWidthByWidgetHeight,
} from '@citadel/tools/utils/sizes';
import { FramePosition } from 'citadel-types';

import { FocusTrapElement, createFocusTrapEl } from './utils/createFocusTrapEl';

const getOrigin = (url: string) => new URL(url).origin;

type OpenedFrameParams = {
  iframeSrc: string;
  title: string;
  id: string;
  isOrder?: boolean;
  onMessage(event: MessageEvent): void;
  onClose(): void;
};

const classForWidgetDialog = 'truv-dialog-root-element';

export abstract class OpenedFrame {
  readonly openTimestamp: number;

  protected readonly iframeRef: HTMLIFrameElement;

  private iframeOrigin: string;
  private readonly onMessage: (e: MessageEvent) => void;
  private readonly onClose: () => void;

  protected constructor(params: OpenedFrameParams) {
    this.onMessage = params.onMessage;
    this.onClose = params.onClose;

    this.openTimestamp = Date.now();

    this.iframeRef = document.createElement('iframe');

    this.iframeRef.style.left = '0';
    this.iframeRef.style.right = '0';
    this.iframeRef.style.top = '0';
    this.iframeRef.style.bottom = '0';
    this.iframeRef.style.border = '0';
    this.iframeRef.title = params.title;
    this.iframeRef.width = '100%';
    this.iframeRef.height = '100%';
    this.iframeRef.id = params.id;
    // We need camera to use face scan verification for doordash for example
    this.iframeRef.allow = 'web-share;camera';
    this.iframeRef.src = params.iframeSrc;

    this.iframeOrigin = getOrigin(params.iframeSrc);

    window.addEventListener('message', this.onMessage);
  }

  public postMessage(message: any) {
    this.iframeRef.contentWindow?.postMessage(message, this.iframeOrigin);
  }

  public changeIframeSrc(src: string) {
    this.iframeOrigin = getOrigin(src);
    this.iframeRef.src = src;
  }

  public changeIframeTitle(title: string) {
    this.iframeRef.title = title;
  }

  public dispose(): void {
    this.onClose();
    window.removeEventListener('message', this.onMessage);
    this.disposeOpenedFrame();
  }

  protected abstract disposeOpenedFrame(): void;

  static createAndShow(position: FramePosition, params: OpenedFrameParams): OpenedFrame {
    if (position.type === 'inline') {
      return new OpenedFrameInDiv(position.container, params);
    }

    if (window.HTMLDialogElement) {
      return new OpenedFrameUsingDialog(params);
    }

    return new OpenedFrameUsingFallback(params);
  }
}

class OpenedFrameInDiv extends OpenedFrame {
  constructor(private parentDiv: HTMLElement, params: OpenedFrameParams) {
    super(params);

    this.parentDiv.appendChild(this.iframeRef);
  }

  public disposeOpenedFrame() {
    this.iframeRef.remove();
  }
}

abstract class FullscreenOpenedFrame extends OpenedFrame {
  protected readonly backgroundColor: string;
  private readonly isOrder: boolean;

  constructor(params: OpenedFrameParams) {
    super(params);

    this.isOrder = !!params.isOrder;

    this.iframeRef.style.top = '50%';
    this.iframeRef.style.left = '50%';
    this.iframeRef.style.transform = 'translate(-50%, -50%)';
    this.iframeRef.style.overflow = 'hidden';
    this.updateSizes();
    window.addEventListener('resize', this.onResize);

    this.backgroundColor = params.isOrder ? 'rgba(0,0,0,0.3)' : 'transparent';

    document.body.style.overflow = 'hidden';

    if (getMobileOperatingSystem() === 'Android') {
      this.iframeRef.style.minHeight = `${window.innerHeight}px`;
    }
  }

  public disposeOpenedFrame() {
    document.body.style.overflow = 'unset';

    window.removeEventListener('resize', this.onResize);
    this.disposeFullscreen();
  }

  protected abstract disposeFullscreen(): void;

  private onResize = () => {
    requestAnimationFrame(() => {
      this.updateSizes();
    });
  };

  private updateSizes = () => {
    const fullScreen = window.innerWidth <= SMALL_SCREEN_WIDTH_PX || !this.isOrder;

    if (fullScreen) {
      this.iframeRef.style.width = '100%';
      this.iframeRef.style.height = '100%';
      this.iframeRef.style.borderRadius = '0px';
    } else {
      const height = getDesktopWidgetHeightByScreenSize(window.innerHeight, false);

      this.iframeRef.style.width = `${getDesktopWidgetWidthByWidgetHeight(height)}px`;
      this.iframeRef.style.height = `${height}px`;
      this.iframeRef.style.borderRadius = '16px';
    }
  };
}

class OpenedFrameUsingDialog extends FullscreenOpenedFrame {
  private dialogRef;

  constructor(params: OpenedFrameParams) {
    super(params);

    this.dialogRef = document.createElement('dialog');
    this.dialogRef.className = classForWidgetDialog;

    this.dialogRef.ariaLabel = params.title;
    this.dialogRef.ariaModal = 'true';

    this.dialogRef.style.all = 'unset';

    const style = document.createElement('style');

    style.textContent = `
      .${classForWidgetDialog}::backdrop {
        background-color: ${this.backgroundColor}; !important;
      }
    `;

    this.dialogRef.appendChild(style);
    this.iframeRef.style.position = 'fixed';
    this.dialogRef.appendChild(this.iframeRef);

    window.document.body.appendChild(this.dialogRef);

    this.dialogRef.showModal();
  }

  protected disposeFullscreen() {
    this.dialogRef.close();
    this.dialogRef.remove();
  }
}

class OpenedFrameUsingFallback extends FullscreenOpenedFrame {
  private divRef;
  private focusTrapEls: [FocusTrapElement, FocusTrapElement];

  constructor(params: OpenedFrameParams) {
    super(params);

    this.divRef = document.createElement('div');
    this.divRef.style.position = 'fixed';
    this.divRef.style.left = '0';
    this.divRef.style.right = '0';
    this.divRef.style.top = '0';
    this.divRef.style.bottom = '0';
    this.divRef.style.background = this.backgroundColor;
    this.divRef.style.zIndex = '2147483646';

    this.focusTrapEls = [createFocusTrapEl(this.iframeRef), createFocusTrapEl(this.iframeRef)];

    this.iframeRef.style.position = 'absolute';

    this.divRef.appendChild(this.focusTrapEls[0].htmlElement);
    this.divRef.appendChild(this.iframeRef);
    this.divRef.appendChild(this.focusTrapEls[1].htmlElement);

    document.body.appendChild(this.divRef);
    this.iframeRef.focus();

    setTimeout(() => {
      this.iframeRef.focus();
    });
  }

  protected disposeFullscreen() {
    this.focusTrapEls.forEach((el) => el.dispose());
    this.divRef.remove();
  }
}
