import { decodeQueryParams } from '@citadel/tools/utils/queryParams';

const paths = (() => {
  const { hostname, href, search } = document.location;
  const queryParams = decodeQueryParams(search);

  const truvTestFlowBasePath = queryParams.TRUV_TEST_FLOW_BASE_PATH as string | undefined;
  const truvTestCdnUrl = queryParams.TRUV_TEST_CDN_URL as string | undefined;

  const flowBasePath = truvTestFlowBasePath ?? process.env.REACT_APP_FLOW_BASE_PATH ?? '';

  const cdnUrl = truvTestCdnUrl ?? process.env.REACT_APP_CDN_URL ?? '';

  const mobilePageResult = href.match(/https?\:\/\/[^\/]+\/([^\/]+)\/mobile.html/);
  const mobileBranchName = mobilePageResult && mobilePageResult[1] ? `/${mobilePageResult[1]}` : '';

  if (mobileBranchName) {
    return {
      classic: flowBasePath.replace(cdnUrl, `${cdnUrl}${mobileBranchName}`),
      new: null,
    };
  }

  // only on demo app domains
  if (/^demo(.*)\.truveng.com$/.test(hostname)) {
    const branchName = queryParams.branch ? `/${queryParams.branch}` : '';

    if (branchName) {
      return {
        classic: flowBasePath.replace(cdnUrl, `${cdnUrl}${branchName}`),
        new: null,
      };
    }
  }

  return {
    classic: flowBasePath,
    new: truvTestFlowBasePath ? null : process.env.REACT_APP_WIDGET_INDEX_PATH ?? null,
  };
})();

export const flowUrlClassic = new URL(paths.classic, document.location.origin).href;
export const flowUrlNew = paths.new ? new URL(paths.new, document.location.origin).href : null;
