const isBrowserSupported = () => {
  return cssPropertySupported('display', ['-ms-flexbox', '-webkit-box', 'flex']) && !isIe();
};

export default isBrowserSupported;

// from https://stackoverflow.com/a/53191596/275473
const cssPropertySupported = (function () {
  const mem: Record<string, unknown> = {}; // save test results for efficient future calls with same parameters

  return function cssPropertySupported(prop: string, values: string[]) {
    if (mem[prop + values]) {
      return mem[prop + values];
    }

    const element = document.createElement('p');

    let index = values.length;
    let name;
    let result = false;

    try {
      while (index--) {
        name = values[index];
        element.style.display = name;

        if (element.style.display === name) {
          result = true;
          break;
        }
      }
    } catch {
      // Empty
    }

    mem[prop + values] = result;

    return result;
  };
})();

function isIe() {
  // https://stackoverflow.com/a/19868056/275473
  return 'ActiveXObject' in window;
}
