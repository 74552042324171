import {
  BridgeInitConfig,
  OrderApiPublicInterface,
  SingleBridgeGuardFunction,
  TRUV_ORDER_MESSAGE_TYPE,
  TruvOrderEventMessage,
  TruvOrderEventType,
} from 'citadel-types';

import { OpenedFrame } from './OpenedFrame';

export class OrderApi implements OrderApiPublicInterface {
  private openedFrame: OpenedFrame | null;

  constructor(
    private readonly config: BridgeInitConfig,
    private readonly singleBridgeGuard: SingleBridgeGuardFunction,
  ) {
    this.openedFrame = null;
  }

  open() {
    const releaseSingleBridgeGuard = this.singleBridgeGuard();

    if (this.openedFrame || releaseSingleBridgeGuard === null) {
      return;
    }

    const position = this.config.position ?? { type: 'dialog' };

    const url = new URL(process.env.REACT_APP_ORDER_URL ?? '');

    url.searchParams.append('bridge_token', this.config.bridgeToken);
    url.searchParams.append('environment', 'iframe');

    this.openedFrame = OpenedFrame.createAndShow(position, {
      isOrder: true,
      iframeSrc: url.toString(),
      title: 'Truv Order',
      id: 'truv-order-flow',
      onMessage: this.handleMessage,
      onClose: releaseSingleBridgeGuard,
    });
  }

  private handleMessage = (event: MessageEvent<TruvOrderEventMessage>) => {
    if (this.openedFrame && event.data?.type === TRUV_ORDER_MESSAGE_TYPE) {
      if (event.data.message.source === TruvOrderEventType.Order && event.data.message.type === 'CLOSE') {
        this.close();
        this.config.onClose?.();
      }

      if (event.data.message.source === TruvOrderEventType.Order && event.data.message.type === 'LOAD') {
        this.config.onLoad?.();
      }

      if (event.data.message.source === TruvOrderEventType.Order && event.data.message.type === 'SUCCESS') {
        this.config.onSuccess?.();
      }

      this.config.onEvent?.(event.data.message.type, event.data.message.payload, event.data.message.source);
    }
  };

  close() {
    if (this.openedFrame) {
      this.openedFrame.dispose();
      this.openedFrame = null;
    }
  }
}
