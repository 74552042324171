export const decodeQueryParams = (query: string): Record<string, string | string[]> => {
  const entries = new URLSearchParams(query).entries();

  const result: Record<string, string | string[]> = {};

  for (const [key, value] of entries) {
    if (result[key]) {
      let currentValue = result[key];

      if (!Array.isArray(currentValue)) {
        currentValue = [currentValue];
        result[key] = currentValue;
      }

      currentValue.push(value);
    } else {
      result[key] = value;
    }
  }

  return result;
};
