export type FocusTrapElement = { htmlElement: HTMLElement; dispose: () => void };

export const createFocusTrapEl = (focusEl: HTMLElement): FocusTrapElement => {
  const onFocus = () => {
    focusEl.focus();
  };

  const htmlElement = document.createElement('div');

  htmlElement.tabIndex = 0;
  htmlElement.addEventListener('focus', onFocus);

  return {
    htmlElement,
    dispose: () => htmlElement.removeEventListener('focus', onFocus),
  };
};
