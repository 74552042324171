export type Metric = {
  name: string;
  value: number;
  type: 'gauge';
  attributes?: Record<string, string | number>;
};

export const send = (metric: Metric) => {
  if (process.env.REACT_APP_METRICS_HOST) {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', process.env.REACT_APP_METRICS_HOST, true);
    xhr.setRequestHeader('Content-Type', 'application/json');

    // eslint-disable-next-line no-console
    console.log('truv_metric', metric);

    const body = JSON.stringify([
      {
        metrics: [
          {
            name: metric.name,
            type: metric.type,
            value: metric.value,
            timestamp: Date.now(),
            attributes: {
              host: window.location.host,
              env: process.env.REACT_APP_TARGET_ENV,
              version: process.env.REACT_APP_VERSION,
              ...(metric.attributes ?? {}),
            },
          },
        ],
      },
    ]);

    xhr.send(body);
  }
};
