export type BackendErrorResponse = {
  error?: {
    code?: string;
    extra?: {
      'invalid-params'?: Array<{ field: string; message: string }>;
      'texts'?: {
        verification_expired_header?: string;
        verification_expired_text?: string;
      };
    };
    message?: string;
  };
};
