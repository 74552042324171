export const DEFAULT_FRAME_POSITION_TYPE = 'dialog';

export type FramePosition =
  | {
      type: 'dialog';
    }
  | {
      type: 'inline';
      container: HTMLElement;
    };
