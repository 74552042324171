import { decodeQueryParams } from '@citadel/tools/utils/queryParams';
import { BridgeInitConfig, SingleBridgeGuardFunction } from 'citadel-types';

import { BridgeApi } from './BridgeApi';
import { OrderApi } from './OrderApi';

let bridgeIsOpened = false;

const singleBridgeGuard: SingleBridgeGuardFunction = () => {
  if (bridgeIsOpened) {
    // eslint-disable-next-line no-console
    console.error('The bridge is already open');

    return null;
  }

  bridgeIsOpened = true;

  return () => {
    bridgeIsOpened = false;
  };
};

const truvBridge = {
  init: (config: BridgeInitConfig) => {
    return config.isOrder ? new OrderApi(config, singleBridgeGuard) : new BridgeApi(config, singleBridgeGuard);
  },
  findGetParameter: (parameterName: string) => {
    const params = decodeQueryParams(window.location.search);

    return params[parameterName];
  },
};

const truvOrder = {
  init: (config: BridgeInitConfig) => new OrderApi(config, singleBridgeGuard),
};

if (typeof window !== 'undefined') {
  Object.defineProperty(window, 'TruvBridge', {
    get() {
      return truvBridge;
    },
  });

  Object.defineProperty(window, 'TruvOrder', {
    get() {
      return truvOrder;
    },
  });

  // it's just to keep compatibility to previous name
  Object.defineProperty(window, 'CitadelBridge', {
    get() {
      return window.TruvBridge;
    },
  });
}
